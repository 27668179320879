<template>
  <gmap-map
    :center="center"
    :zoom="12"
    ref="mapRef"
    style="width: 100%; height: 400px"
  >
    <gmap-info-window
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen = false"
    >
      <div v-html="infoContent"></div>
    </gmap-info-window>
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      @click="
        panTo(m.position);
        toggleInfoWindow(m, index);
      "
      :clickable="true"
      :draggable="false"
      :icon="iconImage"
    ></gmap-marker>
  </gmap-map>
</template>

<script>
import image from '@/assets/lightning.png';
import oil from '@/assets/oil.png';
import factory from '@/assets/factory.png';
import { gmapApi } from 'vue2-google-maps';

export default {
  name: 'google-map-pins',
  data() {
    return {
      // default to Bangkok to keep it simple
      // change this to whatever makes sense
      // center: { lat: 13.772188, lng: 100.480889 },
      // markers: [],
      // places: [],
      image: image,
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infoWindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
    pinImage: {
      type: String,
      default: null,
    },
  },
  mounted() {},

  computed: {
    google: gmapApi,
    iconImage() {
      var icon = {
        url: this.pinImage || image, // url
        scaledSize: new this.google.maps.Size(50, 50), // scaled size
        origin: new this.google.maps.Point(0, 0), // origin
        anchor: new this.google.maps.Point(25, 50), // anchor
      };
      return icon;
    },
    zoom() {
      if (!this.markers || this.markers.length <= 0) {
        return 5;
      }
      return 12;
    },
    center() {
      // return { lat: 13.772188, lng: 100.480889 }; // mock
      if (!this.markers || this.markers.length <= 0) {
        return { lat: 13.772188, lng: 100.480889 };
      }
      const count = this.markers.length || 1;
      let sumX = 0;
      let sumY = 0;
      let sumZ = 0;

      for (var i = 0; i < this.markers.length; i++) {
        const lat = this.degr2rad(this.markers[i].position.lat);
        const lng = this.degr2rad(this.markers[i].position.lng);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
      }

      const avgX = sumX / count;
      const avgY = sumY / count;
      const avgZ = sumZ / count;

      // convert average x, y, z coordinate to latitude and longtitude
      const lngCentered = Math.atan2(avgY, avgX);
      const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
      const latCentered = Math.atan2(avgZ, hyp);

      return {
        lat: this.rad2degr(latCentered),
        lng: this.rad2degr(lngCentered),
      };
    },
    markers() {
      return this.locations.map((location) => {
        return {
          title: location.title,
          position: {
            lat: location.lat,
            lng: location.long,
          },
        };
      });
    },
  },

  watch: {
    markers: {
      deep: true,
      handler: 'watchHandler',
    },
  },

  methods: {
    rad2degr(rad) {
      return (rad * 180) / Math.PI;
    },
    degr2rad(degr) {
      return (degr * Math.PI) / 180;
    },
    panTo(position) {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo(position);
        });
      }
    },
    watchHandler(newVal, oldVal) {
      const markers = newVal;
      const distinct = [...new Set(markers.map(this.markerHashCode))];
      if (distinct.length > 2) {
        const bounds = new this.google.maps.LatLngBounds();
        for (let m of markers) {
          const position = new this.google.maps.LatLng(
            m.position.lat,
            m.position.lng
          );
          bounds.extend(position);
        }
        this.$refs.mapRef.fitBounds(bounds);
      } else if (!markers || markers.length <= 0) {
        this.$refs.mapRef.$mapObject.setZoom(5);
      }
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.title;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infoWindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    markerHashCode(item) {
      return (23 * 31 + item.position.lat) * 31 + item.position.lng;
    },
  },
};
</script>

<style scoped></style>
